import { toQueryParamsStr } from "./arrayUtils";
import { notNullNotUndefined } from "./objectUtils";

export const absoluteUrl = (url) => {
    const baseUrl = typeof window !== 'undefined' && window.location.protocol + '//' + window.location.host;
    return baseUrl + url;
}

export const absoluteUrlWithSubdomain = (subdomain, url) => {
    notNullNotUndefined(subdomain)
    const baseUrl = typeof window !== 'undefined' && window.location.protocol + '//' + subdomain + '.' + window.location.host;
    return baseUrl + url;
}

export const appRoutes = {
    backlogs: {
        buildPath: (slug) => {
            if (!slug) {
                return '/ideaspaces';
            }
            return '/ideaspaces/' + sanitizeSlug(slug);
        },
        rating: {
            buildPath: (slug) => {
                notNullNotUndefined(slug);
                return '/ideaspaces/' + sanitizeSlug(slug) + '/rating';
            },
        },
        portal: {
            buildPath: (slug) => {
                notNullNotUndefined(slug);
                return '/ideaspaces/' + sanitizeSlug(slug) + '/portal';
            },
        },
    },
    vsprio: {
        lists: {
            buildPath: (slug) => {
                if (!slug) {
                    return '/lists';
                }
                return `/lists/${sanitizeSlug(slug)}`
            },
        },
        listsConnect: {
            buildPath: (slug, integrationId) => {
                notNullNotUndefined(slug)
                notNullNotUndefined(integrationId)
                return `/lists/${sanitizeSlug(slug)}/connect?integrationId=${sanitizeSlug(integrationId)}`
            },
        },
        listsNew: {
            buildPath: (integrationId) => {
                let params = ""
                if (integrationId) {
                    params = `?integrationId=${integrationId}`
                }
                return `/lists/new${params}`
            },
        },
        rooms: {
            buildPath: (slug) => {
                if (!slug) {
                    return '/rooms';
                }
                return '/rooms/' + sanitizeSlug(slug);
            },
            rankNow: {
                buildPath: (slug) => {
                    notNullNotUndefined(slug)
                    return `/rooms/${sanitizeSlug(slug)}?action=rank`
                }
            },
        },
        roomsRating: {
            buildPath: (slug) => {
                if (!slug) {
                    return '/r';
                }
                return '/r/' + sanitizeSlug(slug);
            },
        },
        roomsCreate: {
            buildPath: () => {
                return '/rooms/new';
            },
        },
        roomsCreateFromTemplate: {
            buildPath: (templateId) => {
                notNullNotUndefined(templateId);
                return '/rooms/new?templateId=' + sanitizeSlug(templateId);
            },
        },
        roomAdminLink: {
            buildPath: (slug, magicKey) => {
                notNullNotUndefined(slug);
                notNullNotUndefined(magicKey);
                return '/rooms/' + sanitizeSlug(slug) + '/admin/' + sanitizeSlug(magicKey);
            },
        },
        helpCenter: {
            buildPath: (ref) => {
                return `/help-center${ref?'?ref='+ref:''}`
            },
            answeringConfidence: {
                buildPath: () => {
                    return '/help-center/406887922439-what-is-the-answering-confidence-in-a-pairwise-ranking-survey'
                }
            }
        }
    },
    backlogsGuest: {
        buildPath: (slug) => {
            if (!slug) {
                return '/ideaspaces';
            }
            return '/ideaspaces/' + sanitizeSlug(slug);
        },
        rating: {
            buildPath: (slug) => {
                notNullNotUndefined(slug);
                return '/ideaspaces/' + sanitizeSlug(slug) + '/rating';
            },
        },
        admin: {
            buildPath: (slug, magicKey) => {
                notNullNotUndefined(slug);
                notNullNotUndefined(magicKey);
                return '/ideaspaces/' + sanitizeSlug(slug) + '/admin/' + sanitizeSlug(magicKey);
            },
        },
        adminAndRateNow: {
            buildPath: (slug, magicKey) => {
                notNullNotUndefined(slug);
                notNullNotUndefined(magicKey);
                return '/ideaspaces/' + sanitizeSlug(slug) + '/admin/' + sanitizeSlug(magicKey) + '?rateNow=true';
            },
        },
        roomNew: {
            buildPath: () => {
                return '/rooms/new';
            },
        },
        roomAsAdmin: {
            buildPath: (slug, magicKey) => {
                notNullNotUndefined(slug);
                notNullNotUndefined(magicKey);
                return '/rooms/' + sanitizeSlug(slug) + '/admin/' + sanitizeSlug(magicKey);
            },
        },
    },
    releasoo: {
        writer: {
            buildPath: () => {
                return '/writer';
            }
        }
    },
    dashboard: {
        buildPath: (slug) => {
            if (!slug) {
                return '/dashboard';
            }
            return '/dashboard/' + sanitizeSlug(slug);
        }
    },
    laanding: {
        pagePreview: {
            buildPath: (slug) => {
                notNullNotUndefined(slug)
                return '/page-preview/?slug=' + sanitizeSlug(slug);
            }
        },
    },
    legal: {
        legalNotice: {
            buildPath: ()=>'/legal-notice'
        },
        privacyPolicy: {
            buildPath: (locale)=>`${locale?'/'+locale:''}/privacy-policy`
        },
        terms: {
            buildPath: (locale)=>`${locale?'/'+locale:''}/terms`
        },
        termsVersion: {
            buildPath: (locale, version)=>{
                notNullNotUndefined(version)
                return `${locale?'/'+locale:''}/terms?version=${version}`
            }
        },
        termsV_1_0: {
            buildPath: ()=>'/terms-1-0'
        },
        imageCredits: {
            buildPath: ()=>'/image-credits'
        }
    },
    yoio: {
        automations: {
            new: {
                buildPath: () => '/app/manage/automations/new'
            }
        },
        workspace: {
            buildPath: () => '/workspace'
        },
        subscribe: {
            buildPath: () => {
                return '/workspace?tab=subscription';
            }
        },
        subscription: {
            checkoutPaddle: {
                buildPath: (planKey, period) => {
                    notNullNotUndefined(planKey)
                    notNullNotUndefined(period)
                    return `/checkout?plan=${planKey}&period=${period}`
                }
            },
            buildPath: (checkoutComplete) => {
                if (checkoutComplete) {
                    return '/workspace?tab=subscription&checkoutComplete=true';
                } else {
                    return '/workspace?tab=subscription';
                }
            }
        },
        app: {
            overview: {
                buildPath: () => '/app/manage'
            },
            workspaces: {
                buildPath: (slug) => {
                    notNullNotUndefined(slug)
                    return `/app/workspaces/${slug}`
                }
            },
            usage: {
                userAccounts: {
                    buildPath: (userSelected) => {
                        notNullNotUndefined(userSelected)
                        return `/app/manage?usageTab=userAccounts${userSelected?`&userSelected=${encodeURIComponent(userSelected)}`:''}`
                    }
                },
            },
            home: {
                buildPath: () => '/'
            },
            websites: {
                buildPath: (slug) => {
                    if (!slug) {
                        return `/app/manage?tab=website`
                    }
                    return `/app/manage/websites/${slug}`
                }
            },
            forms: {
                buildPath: (slug) => {
                    if (!slug) {
                        return `/app/manage?tab=website`
                    }
                    return `/app/manage/forms/${slug}`
                },
                records: {
                    buildPath: (slug) => {
                        return `/app/manage/forms/${slug}/records`
                    },
                },
            },
        },
        landingPage: {
            home: {
                buildPath: () => '/'
            },
            productPage: {
                buildPath: () => '/features'
            },
            pricingPage: {
                buildPath: () => '/pricing'
            },
            howItWorksPage: {
                buildPath: () => '/how-it-works'
            },
            blog: {
                buildPath: (ref) => `/blog${ref?'?ref='+ref:''}`
            },
        },
        access: {
            getStarted: {
                buildPath: () => '/signup'
            },
            getStartedWithPricingPlan: {
                buildPath: () => '/signup'
            },
            signup: {
                buildPath: (ref, workspace, hrd, switchUser) => {
                    let params = []
                    if (hrd) {//pseudo ID to better differentiate links. value is not used anywhere
                        params.push('hrd', hrd)
                    }
                    if (ref) {
                        params.push('ref', ref)
                    }
                    if (workspace) {
                        params.push('joinWorkspace', workspace)
                    }
                    if (switchUser) {
                        params.push('switchUser', true)
                    }
                    return `/signup${toQueryParamsStr(params)}`;
                }
            },
            signupFinish: {
                buildPath: (ref, workspace) => {
                    let params = []
                    if (ref) {
                        params.push('ref', ref)
                    }
                    if (workspace) {
                        params.push('joinWorkspace', workspace)
                    }
                    return `/signup-finish${toQueryParamsStr(params)}`;
                }
            },
            login: {
                buildPath: () => {
                    return '/login';
                }
            },
            loginDone: {
                buildPath: () => {
                    return '/login-done';
                }
            },
            logout: {
                buildPath: () => {
                    return '/logout';
                }
            },
            passwordReset: {
                buildPath: () => {
                    return '/password-reset';
                }
            },
            passwordResetNew: {
                //might be called from api generated links
                buildPath: () => {
                    return '/password-reset-new';
                }
            }
        },
        help: {
            chat: {
                buildPath: () => {
                    return '/help/chat';
                }
            }
        },

    }
};

function sanitizeSlug(slug) {
    return encodeURIComponent(slug)
}